import "./NavBar.css";
import downloadButton from "./image/download-btn.svg";

const NavBar = () => {
  return (
    <div className="nav-container">
      <div className="content-wrap">
        <div id="logo">
          <h1>
            <a href="#/">yesjamstudio</a>
          </h1>
        </div>
        <div className="menu-nav">
          <nav>
            <ul>
              <li>
                <a href="mailto: onejae@gmail.com">Send Email</a>
                {/* <div style={{ marginLeft: "20px", display: "inline-block" }}> */}
                  {/* <a href="mailto: onejae@gmail.com"> */}
                    {/* <p style={{ color: "black" }}>onejae@gmail.com</p> */}
                  {/* </a> */}
                {/* </div> */}
              </li>
              <li className="download-btn">
                <a href="#/download">
                  <img alt="what" src={downloadButton}></img>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
