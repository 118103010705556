import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { isMobile } from "react-device-detect";

import Main from "./Main";
import MainMobile from "./MainMobile";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">{isMobile ? <MainMobile /> : <Main />}</Route>
      </Switch>
    </Router>
  );
}

export default App;
