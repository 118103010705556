import "./NavBarMobile.css";
// import downloadButton from "./image/download-btn.svg";

const NavBar = (props: any) => {
  return (
    <div className="mobile-nav-container">
      <div className="content-wrap">
        <div id="logo" style={{ opacity: 1 - props.fadeout }}>
          <h1>
            <a href="#/">yesjamstudio</a>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
