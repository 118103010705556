import { useEffect, useState } from "react";
import NavBar from "./NavBarMobile";

import { isAndroid } from "react-device-detect";
import "./MainMobile.css";

import appstoreBtn from "./image/appstore.png";
import playstoreBtn from "./image/playstore.png";
import screenshot1 from "./image/screenshot-1.png";

const Page = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fadeOut = Math.min(1, scrollY / 500);

  return (
    <div className="mobileContainer">
      <NavBar fadeout={fadeOut} />
      <div
        className="background"
        style={{
          opacity: 1 - fadeOut,
        }}
      />
      <article className="article-container">
        <div id="maincopy" className="article-wrap">
          <div className="centered">
            <div className="content-wrap">
              <h1>Jazz</h1>
              <h1>Metronome</h1>
              <div className="title-line" />
              <h3 className="introduction">
                A metronome for jazz practice, free
              </h3>
              <div className="download-container">
                <div className="app-download-btn">
                  {isAndroid ? (
                    <a href="https://play.google.com/store/apps/details?id=com.yesjamstudio.keymetronome">
                      <img alt={"fei"} src={playstoreBtn} />
                    </a>
                  ) : (
                    <a href="https://apps.apple.com/us/app/keymetronome/id1663454344">
                      <img alt={"jweio"} src={appstoreBtn} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="userguide" className="article-wrap">
          <img alt="hi" width={200} src={screenshot1} />
        </div>
      </article>
    </div>
  );
};

export default Page;
